import TeamCalculation from "@/models/sporteventCalculation/TeamCalculation";
import BaseService from "../BaseService";

class TeamCalculationService extends BaseService<TeamCalculation> {
  public url: string;

  constructor(url = "sportevent-calculation/teamcalculation/") {
    super(url, "");
    this.url = url;
  }
}
export default new TeamCalculationService();
